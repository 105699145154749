<template>
	<div class="p-3">
		<div class="row">
			<div class="col px-5">
				<form @submit.prevent="save">
					<div class="form-group">
						<h5>Version</h5>
						<input class="form-control" disabled v-model="model.Version"></input>
					</div>

					<div class="form-group" v-if="config">
						<h5>Paylines</h5>
						<valid-paylines-list v-model="lines"
											 :valid-paylines="config.valid_paylines"></valid-paylines-list>
					</div>

					<div class="form-group">
						<h5>Reel filters</h5>
						<h6>Each row is a filter</h6>
						<reel-filters :config="config" v-model="model.ReportOptions.reel_filters"></reel-filters>
					</div>


					<div class="row">
						<div class="col">
							<div class="form-group">
								<h5>Notes</h5>
								<textarea v-model="model.Notes" class="form-control"></textarea>
							</div>
						</div>
					</div>

					<button type="submit" class="btn btn-primary px-5 py-2" :disabled="saving">
						<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
							  v-if="saving"></span>
						CHECK COMBINATIONS
					</button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import Multiplier from "@/components/slot-config-editor/prize/multiplier";
	import api from '@/api'
	import Index from "@/components/slot-simulator/index";

	import StandardReportForm from '@/components/reports/types/standard-report-form'
	import StressReportForm from '@/components/reports/types/stress-report-form'
	import ReelFilters from "../../../../../../../components/combinations/reel-filters";
	import ValidPaylinesList from "../../../../../../../components/valid-paylines-list";

	export default {
		components: {ValidPaylinesList, ReelFilters, Index, Multiplier, StandardReportForm, StressReportForm},
		data() {
			return {
				config: null,
				saving: false,
				lines: [],
				model: {
					ReportOptions: {
						//lines: [],
						reel_filters: []
					}
				}
			}
		},
		async mounted() {
			let tmp = await api.Projects.getProjectConfig(this.$route.params.cuuid)
			this.config = tmp.Configuration

			this.lines = this.config.valid_paylines
		},
		methods: {
			async save() {
				this.saving = true
				let report = await api.CombinationReports.saveNew({
					ReportOptions: {
						reel_filters: this.filterToSend
					},
					Configuration: this.config,
					Version: this.config.version,
					configuration_uuid: this.$route.params.cuuid,
					lines: this.lines

				}).catch(() => this.saving = false)
				this.$router.push({
					name: 'projects-editor-uuid-cuuid-tests-combinations-index',
					params: {
						report_id: report.Id
					}
				})
				this.saving = false
			}
		},
		computed: {
			filterToSend() {
				let reels = {}

				for (let filter_id in this.model.ReportOptions.reel_filters) {
					const filter = this.model.ReportOptions.reel_filters[filter_id]

					for (let reel_id in filter) {
						let valid_symbol = filter[reel_id]
						if (undefined === reels[reel_id]) {
							reels[reel_id] = []
						}
						if (valid_symbol) {
							if (!reels[reel_id].includes(valid_symbol)) {
								reels[reel_id].push(valid_symbol)
							}
						}
					}
				}

				return reels
			},
		}
	}
</script>
