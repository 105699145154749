<template>
	<div class="d-flex d-inline-flex">
		<symbol-switcher :config="config" :enable-null="true" v-for="(symbol, index) in model" :key="index" v-model="model[index]"></symbol-switcher>
	</div>
</template>

<script>
	import SymbolSwitcher from "../slot-config-editor/symbols/symbol-switcher";
	export default {
		name: "reel-filter",
		components: {SymbolSwitcher},
		props: {
			value: {
				type: Array,
				default: () => []
			},
			config: {
				type: Object
			}
		},
		created() {
			if (this.model === null) {
				this.model = this.config.reels.map(r => null)
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit("input", val)
				}
			}
		}
	}
</script>

<style scoped>

</style>
