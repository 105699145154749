<template>
    <div>
        <div v-for="(filter, index) in model" class="my-3 d-flex align-items-center">
            <reel-filter :config="config" v-model="model[index]"></reel-filter>
            <div>
                <button class="btn" @click.prevent="removeFilter(index)">
                    <i class="fa fa-trash text-light"></i>
                </button>
            </div>
        </div>
        <button class="btn btn-primary btn-sm" @click.prevent="addFilter">ADD FILTER</button>
    </div>
</template>

<script>
    import ReelFilter from "./reel-filter";

    export default {
        name: "reel-filters",
        components: {ReelFilter},
        props: {
            value: {
                default: () => [],
                type: Array
            },
            config: Object
        },
        methods: {
            addFilter() {
                this.model.push(null)
            },
            removeFilter(index) {
                console.log('index', index)
                this.model.splice(index, 1)
            }
        },
        computed: {
            model: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit("input", val)
                }
            }
        }
    }
</script>

<style scoped>

</style>
